import React from "react";
import "./style.css";

function Notices() {
  return (
    <div className="company-container">
      <h1 className="title">公告</h1>
      <p className="description">
        ここに会社からの最新の公告やお知らせを掲載します。新しい製品のリリース情報、
        重要な会社の変更点、イベントやウェビナーの案内など、タイムリーな情報を共有します。
      </p>
    </div>
  );
}

export default Notices;
