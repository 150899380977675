import React from "react";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Company from "./pages/Company";
import Notices from "./pages/Notices";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/notices" element={<Notices />} />
        <Route path="/" element={<Company />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
