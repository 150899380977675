import React from "react";
import { Link } from "react-scroll";

function Header() {
  const logoUrl = `${process.env.PUBLIC_URL}/logo.jpg`;
  return (
    <>
      <header>
        <h1 id="logo">
          <a href="/">
            <img src={logoUrl} alt="IC2 Inc" />
          </a>
        </h1>
        <nav id="menubar">
          <ul className="nav-links">
            {/* <li>
              <Link to="#new">新着情報</Link>
            </li> */}
            <li>
              <Link to="company" smooth={true} duration={500}>
                会社概要
              </Link>
            </li>
            <li>
              <Link to="service" smooth={true} duration={500}>
                事業内容
              </Link>
            </li>
            <li>
              <Link to="history" smooth={true} duration={500}>
                沿革
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}

export default Header;
