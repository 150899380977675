import React from "react";
import "./style.css";

function Company() {
  return (
    <>
      <div id="container">
        <div id="contents">
          {/* <section id="new">
            <h2>新着情報</h2>
            <dl>
              <dt>20XX/00/00</dt>
              <dd>第一期広告</dd>
            </dl>
          </section> */}

          <section id="company">
            <h2>会社概要</h2>
            <table className="ta1">
              <caption></caption>
              <tbody>
                <tr>
                  <th>会社名</th>
                  <td>株式会社IC2</td>
                </tr>
                <tr>
                  <th>設立年月日</th>
                  <td>2024年3月12日</td>
                </tr>
                <tr>
                  <th>所在地</th>
                  <td>神奈川県横浜市西区北幸二丁目10番48号　むつみビル3階</td>
                </tr>
                <tr>
                  <th>代表者名</th>
                  <td>小川　浩史</td>
                </tr>
                <tr>
                  <th>資本金</th>
                  <td>100万円</td>
                </tr>
                <tr>
                  <th>従業員数</th>
                  <td>1名</td>
                </tr>
              </tbody>
            </table>
          </section>

          <section id="service">
            <h2>事業内容</h2>

            <div className="list">
              <p>
                弊社は、最先端の技術と深い業界知識を持ち、幅広い業務委託サービスを提供しています。
                専門性を活かし、以下のようなサービスを提供します。
              </p>
            </div>

            <div className="list">
              {/* <img src="../assets/images/sample1.jpg" alt="" /> */}
              <h4>フロントエンド開発</h4>
              <p>
                モダンなユーザインタフェースデザインとユーザエクスペリエンスを提供します。アクセシビリティとレスポンシブデザインを重視し、あらゆるデバイスでの最適な表示を提供します。
              </p>
            </div>
            <div className="list">
              <h4>バックエンド開発</h4>
              <p>
                効率的なデータハンドリングとビジネスロジックの処理を実現。高速なバックエンドAPIの開発により、スムーズなデータフローとシームレスなユーザー体験を提供します。
              </p>
            </div>

            <div className="list">
              <h4>モバイルアプリ開発</h4>
              <p>
                最新の技術を活用して、Androidプラットフォーム向けに最適化されたアプリケーションを開発します。ユーザーが直感的に操作できる美しいインターフェースと、快適なユーザーエクスペリエンスを実現します。
              </p>
            </div>
          </section>

          <section id="history">
            <h2>沿革</h2>
            <div className="list">
              <h4>2024年3月</h4>
              <p>神奈川県横浜市にて、株式会社IC2を設立</p>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Company;
