import React from "react";

function Footer() {
  return (
    <footer>
      <small>Reserved. Copyright 2024 IC2 Inc. All Rights Reserved.</small>
    </footer>
  );
}

export default Footer;
